import { createStore } from 'redux';

const initialState = {
  is_login: false,
  uuid: '',
  username: '',
  email: '',
  phone: '',
  country_code: '',
  is_cert: '',
  is_email_verified: true,
  is_vip: '',
  name: '',
  nickname: '',
  profile_img: '',
  register_country: '',
  register_type: '',
  user_cash_krw: '',
  user_cash_usd: '',
  user_cash_eur: '',
  currency: '',
  user_header_banner_img: '',
  vip_type: '',
  vip_end_datetime: '',
  user_website: '',
  user_introduce: '',
  seller_account_status: '',
  seller_accounts: '',
  bank_list: '',
  cs_view_item: '',
  style: [],
  category: [],
  license_price: '',
  notice_count: 0,
  interest_list: [],
  interest_group: [],
  popup_list: [],
  generate_price: '',

  totalCartCount:0,
  cartCount : 0,
  aiCartCount : 0

};

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    case 'logout':
      return { undefined, action };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
