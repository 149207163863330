/* eslint-disable consistent-return */
/** @format */

import axios from 'axios';
import store from 'store';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

apiClient.interceptors.request.use((request) => {
  // console.log('Request:', request); // 요청 로그
  const accessToken = store.get('accessToken');
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
    request.headers['x-access-token'] = accessToken;
    request.headers['Accept'] = 'application/json, multipart/form-data, application/x-www-form-urlencoded';
  }
  return request;
});

apiClient.interceptors.response.use(undefined, (error) => {
  const { response } = error;
  const { data, status } = response;
  // console.log('response:', response); // 요청 로그
  // console.log('data:', data); // 요청 로그
  // console.log('status:', status); // 요청 로그
  if (status === 401) {
    if (data.message === 'No token provided!!' || data.message === '인증이 만료되었습니다.') {
      store.remove('accessToken');
      store.remove('userData');
      window.location.href = '#/auth/login';
      return window.location.reload();
    }
    if (store.get('lang') === 'kr') {
      return alert(data.message_ko);
    } else {
      return alert(data.message_en);
    }
  } else if (status !== 200 && status !== 404) {
    if (data.message !== 'No token provided!!') {
      if (store.get('lang') === 'kr') {
        alert(data.message_ko);
      } else {
        alert(data.message_en);
      }
      return data;
    }
  }
});

export default apiClient;
